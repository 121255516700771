<template>
  <div class="div">
    <heads />
    <div class="notice-div">
      <!-- <div class="el-menu">
        <div v-for="(item,k) in menuList"
          :key="k"
          :class="menuListClass === k?'active':''"
          class="el-menu-list"
          @click="handleSelect(item,k)">{{item.name}}</div>
      </div> -->
      <div class="notice-article">
        <div v-for="(item,k) in table"
          :key="k"
          class="notice-article-table">
          <router-link :to="{name: 'articleDetails',query: {id: item.id}}">
            <div class="notice-article-div">
              <div class="notice-article-title">{{item.title}}
              </div>
              <div class="notice-article-time">{{item.createTime}}
              </div>
              <div class="notice-article-content"
                v-html="item.content" />
            </div>
          </router-link>

        </div>
      </div>
      <div>
        <pagination v-show="total>=20"
          :page-size="pageSize"
          :total="total"
          :current-page="page"
          @current-change="roleCurrentChange"
          @size-change="sbjSizeChange" />
      </div>
    </div>
    <foots class="foots" />
    <!-- <contant /> -->
  </div>
</template>
<script>
/* eslint vue/no-v-html:0 */
import _ from 'lodash';
import dayjs from 'dayjs';
import heads from './common/head';
import foots from './common/foot';
import pagination from './common/pagination';
import { classifyGet, articleListGet } from './api.js';
// import contant from './common/contant';

export default {
  components: {
    heads,
    foots,
    pagination,
    //  contant
  },
  data() {
    return {
      table: [],
      menuList: [],
      pageSize: 20,
      page: 1,
      total: 0,
      display: true,
      menuListClass: 0,
      itemTitle: '',
      // bodyHeight: '',
    };
  },
  watch: {},
  created() {
    this.getList();
    // this.$nextTick(() => {
    //   window.onscroll = () => {
    //     if (this.bodyHeight !== this.$refs.div.clientHeight) {
    //       this.bodyHeight = this.$refs.div.clientHeight;
    //     }
    //   };
    // });
  },
  destroyed() {
    // 销毁滚动事件
    window.onscroll = null;
  },
  // created(){},
  methods: {
    // 新闻通知列表
    getList() {
      classifyGet({ groupKey: 2 }).then(({ data, err }) => {
        if (!err) {
          const { data: datas = [] } = data;
          const temp = _.head(datas) || {};
          this.menuList = datas;
          this.handleSelect(temp, 0);
        }
      });
    },
    handleSelect(item, k) {
      const para = {
        page: this.page,
        pageSize: this.pageSize,
        length: 80,
        status: '1',
        source: 'szjcyyy',
      };
      articleListGet(para).then(({ data, err }) => {
        if (!err) {
          const { data: datas = [] } = data;
          _.each(datas, v => {
            this.table.push({
              ...v,
              createTime: v.createTime ? dayjs(v.createTime).format('YYYY-MM-DD') : '',
            });
          });
        }
      });
      this.menuListClass = k;
    },
    // 文章详情返回
    returnClick() {
      this.display = true;
    },
    /**
     * 分页
     */
    sbjSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    roleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.div {
  background: rgba(250, 250, 250, 1);
  .notice-div {
    width: 1210px;
    min-height: 650px;
    margin: 0 auto;
    padding-top: 139px;
    .pagination {
      background: rgba(250, 250, 250, 1);
    }
    .notice-article {
      border-bottom: 1px solid rgba(232, 232, 232, 1);
      width: 1200px;
      margin: 0 auto;
      .notice-article-table {
        height: 164px;
        width: 1200px;
        border-top: 1px solid rgba(232, 232, 232, 1);
        padding-top: 30px;
        &:hover {
          width: 1200px;
          height: 164px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
          border: none;
        }
        .notice-article-div {
          width: 1100px;
          margin: 0 auto;
          .notice-article-title {
            font-size: 22px;
            color: rgba(38, 38, 38, 1);
          }
          .notice-article-time {
            font-size: 16px;
            color: rgba(89, 89, 89, 1);
            margin-top: 9px;
          }
          .notice-article-content {
            font-size: 16px;
            color: rgba(140, 140, 140, 1);
            margin-top: 16px;
          }
        }
      }
    }
  }
  .foots {
    margin-top: 50px;
  }
}
</style>
