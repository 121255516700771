var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-pagination", {
    staticClass: "pagination",
    attrs: {
      total: _vm.total,
      "page-size": _vm.pageSize,
      "page-sizes": _vm.pageSizes,
      "current-page": _vm.currentPage,
      layout: _vm.layout,
      background: ""
    },
    on: { "current-change": _vm.currentChange, "size-change": _vm.sizeChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }