var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "div" },
    [
      _c("heads"),
      _c("div", { staticClass: "notice-div" }, [
        _c(
          "div",
          { staticClass: "notice-article" },
          _vm._l(_vm.table, function(item, k) {
            return _c(
              "div",
              { key: k, staticClass: "notice-article-table" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "articleDetails", query: { id: item.id } }
                    }
                  },
                  [
                    _c("div", { staticClass: "notice-article-div" }, [
                      _c("div", { staticClass: "notice-article-title" }, [
                        _vm._v(_vm._s(item.title) + "\n            ")
                      ]),
                      _c("div", { staticClass: "notice-article-time" }, [
                        _vm._v(_vm._s(item.createTime) + "\n            ")
                      ]),
                      _c("div", {
                        staticClass: "notice-article-content",
                        domProps: { innerHTML: _vm._s(item.content) }
                      })
                    ])
                  ]
                )
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          [
            _c("pagination", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.total >= 20,
                  expression: "total>=20"
                }
              ],
              attrs: {
                "page-size": _vm.pageSize,
                total: _vm.total,
                "current-page": _vm.page
              },
              on: {
                "current-change": _vm.roleCurrentChange,
                "size-change": _vm.sbjSizeChange
              }
            })
          ],
          1
        )
      ]),
      _c("foots", { staticClass: "foots" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }